
import { StyleSheet } from 'aphrodite';


// Themes
const darkTheme = {
    body: '#363537',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
}

const lightTheme = {
    body: '#E2E2E2',
    text: '#000000',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)'
}

const styles = StyleSheet.create({
    
});

const propStyles = (props) => StyleSheet.create({

});

export {

    // themes 

    darkTheme,
    lightTheme,

    // styles

    propStyles,
    styles,
}
