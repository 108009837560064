import React, { useState } from 'react';

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';

import { css, StyleSheet } from 'aphrodite/no-important';
import { lightTheme, darkTheme } from './styles/styles';

import logoDark from './assets/daiguren_dark_bg.png';
import logoLight from './assets/daiguren_light_bg.png';
import './App.css';

function App() {

  const [theme, setTheme] = useState('light');

  const setDarkTheme = () => {
    setTheme('dark');
  }

  const setLightTheme = () => {
    setTheme('light');
  }

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <div className={css([extraStyles.rootCont])}>
          <img src={theme === 'light' ? logoLight : logoDark} className={css([extraStyles.daigurenLogo])} alt="logo" />
          {/* <h1 className={ css([extraStyles.comingSoonText])}>
            A place for Flâneurs & Jed McKenna fans
          </h1> */}
        </div>
      </>
    </ThemeProvider>
  );
}

const extraStyles = StyleSheet.create({
  rootCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#EEEEEE',
  },
  daigurenLogo: {
    height: '40vmin',
    pointerEvents: 'none',
  },
  comingSoonText: {
    marginTop: '5vh',
    textAlign: 'center',
  }
})

export default App;
